/* tournament bracket styles */
.bracket {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    font-size: 0;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    -khtml-border-radius: 5px;
}

.bracket:before, .bracket:after {
    content: " ";
    display: table;
}
.bracket:after {
    clear: both;
}
.bracket li {
    position: relative;
    display: inline-block;
    width: 50%;
    min-height: 1px;
    padding: 0 0 1em;
    vertical-align: top;
    font-size: 1em;
    text-align: left;
}

/* bracket header */

.bracket .match-header {
    padding: 0.5em 1em;
    background: #eee;
    border-bottom: 1px solid #ccc;
    border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -o-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    -khtml-border-radius: 5px 5px 0 0;
}

.bracket .match-header h4 {
    margin: 0;
    font-size: 1em;
    font-weight: normal;
}

/* bracket round */

.bracket .round {
    padding: 1em 0;
}

.bracket .round:last-child {
    padding-bottom: 0;
}

.bracket .round:before, .bracket .round:after {
    content: " ";
    display: table;
}

.bracket .round:after {
    clear: both;
}

.bracket .round li {
    padding: 1em 0;
}

.bracket .round li:last-child {
    padding-bottom: 0;
}

.bracket .round li .spacer {
    display: none;
}
